import { useMatches } from "@remix-run/react";

import type { Company } from "@wind/db/prisma";

import { wrapperRoutes } from "./wrapperRoutes.js";

export const useOptionalCompany = () => {
  const matches = useMatches();

  const { company } =
    (matches.find((match) => wrapperRoutes.includes(match.id))?.data as { company?: Company }) ??
    {};

  if (!company) {
    return null;
  }
  return company as Company;
};

export const useCompany = () => {
  const company = useOptionalCompany();
  if (!company) {
    throw new Error("No company found. Use this hook only on company pages.");
  }
  return company;
};
